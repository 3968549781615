import UnitsInServiceComponent from '@/components/units-in-service/UnitsInServiceComponent.vue'
import UnitsStateComponent from '@/components/units-state/UnitsStateComponent.vue'
import WorkingAverageSpeedComponent from '@/components/working-average-speed/WorkingAverageSpeedComponent.vue'
import ProductiveTimeComponent from '@/components/productive-time/ProductiveTimeComponent.vue'
import TractorDischargeComponent from '@/components/tractor-discharge/TractorDischargeComponent.vue'
import AlertNotificationComponent from '@/components/commons/alert-notification/AlertNotificationComponent.vue'
import WorkFrontsStateComponent from '@/components/work-fronts/WorkFrontsStateComponent.vue'
import StoppedWorkFrontsComponent from '@/components/stopped-work-fronts/StoppedWorkFrontsComponent.vue'
import EngineStateComponent from '@/components/engine-state/EngineStateComponent.vue'
// import { SECTOR_KEY } from '@colven/common-domain-lib/lib'

export default {
  name: 'Dashboard',
  components: {
    UnitsInServiceComponent,
    UnitsStateComponent,
    WorkingAverageSpeedComponent,
    ProductiveTimeComponent,
    AlertNotificationComponent,
    TractorDischargeComponent,
    WorkFrontsStateComponent,
    StoppedWorkFrontsComponent,
    EngineStateComponent
  },
  data: () => ({
    sector: null
  }),
  beforeCreate () {
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    // Setea el lenguaje de los componentes de Vuetify
    this.$vuetify.lang.current = this.$i18n.locale
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  computed: {
  },
  methods: {
  }
}
